import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import RegionsSlider from "../components/regions-slider";
import Slideshow from "../components/slideshow";
import FaqSection from "../components/faq-section";
import GradientSection from "../components/gradient-section";
import BookingBanner from "../components/booking-banner";
import { Link } from "gatsby";
import { INLINES, MARKS } from "@contentful/rich-text-types";

const RegionsTemplate = ({ data }) => {
  const { title, main_title, thumbnail, content } = data.contentfulPage;
  const intl = useIntl();
  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2-editorial">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3-editorial">{children}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <figure className="image-editorial">
          <Img
            fluid={node.data.target.fluid}
            alt={node.data.target.description}
          />
        </figure>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => (
        <BookingBanner title={node.data.target.title} />
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link to={`/${intl.locale}/${node.data.target.slug}/`}>{children}</Link>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <Layout>
      <Seo
        title={title}
        description={main_title}
        image={thumbnail && thumbnail.file.url}
      />
      <article>
        <section className="section section-white">
          <div
            className={`introduction${
              thumbnail ? " introduction-hero" : " introduction-clouds"
            }`}
          >
            <div className="introduction-container">
              <div className="introduction-inner">
                {thumbnail && (
                  <Img
                    className="introduction-image"
                    fluid={thumbnail.fluid}
                    loading="eager"
                    alt={title}
                  />
                )}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <p className="eyebrow">{title}</p>
                      <h1>{main_title}</h1>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-gradient overflow-hidden">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
              {content?.map((block) => {
                switch (block.__typename) {
                  case "ContentfulTexte":
                    return (
                      <section class="section section-white">
                        <div class="container">
                          <div class="row justify-content-center">
                            <div class="col-lg-8">
                              <p class="lead">
                                {block.content && renderRichText(block.content,renderOptions)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    );

                  case "ContentfulDiaporama":
                    return (
                      <Slideshow
                        title={block.title}
                        text={block.text}
                        slides={block.slides}
                        key={block.contentful_id}
                      />
                    );

                  case "ContentfulFaqRubrique":
                    return (
                      <FaqSection
                        title={block.title}
                        questions={block.questions}
                        key={block.contentful_id}
                      />
                    );

                  case "ContentfulGradientSection":
                    return ( 
                      <section className="section section-gradient">
                        <GradientSection block={block}/>
                      </section>
                    );

                  default:
                    return null;
                }
              })}
              </div>
            </div>
            <aside className="mt-16 mt-md-30">
              <h2>
                <FormattedMessage id="guide.destination" />
              </h2>
              <div className="row mt-8 mt-md-10">
                <div className="col-8 col-sm-10 col-lg-12 position-static">
                  <LazyLoad>
                    <RegionsSlider />
                  </LazyLoad>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default RegionsTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      subtitle
      category
      content {
        ... on ContentfulTexte {
          contentful_id
          __typename
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              ... on ContentfulBanniereDeReservation {
                contentful_id
                __typename
                title
              }
            }
          }
        }
        ... on ContentfulDiaporama {
          contentful_id
          __typename
          title
          text
          slides {
            ... on ContentfulActivite {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            ... on ContentfulService {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        ... on ContentfulFaqRubrique {
          contentful_id
          __typename
          title
          questions {
            contentful_id
            question
            answer {
              raw
            }
          }
        }
        ... on ContentfulGradientSection {
          id
          title
          __typename
          contentGradient: content {
            ... on ContentfulImageTexte {
              id
              title
              image {
                fluid(maxHeight: 650, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
              }
              contentText {
                raw
              }
              imageDisplay
            }
            ... on ContentfulListeDePartenaires {
              id
              title
              partners {
                id
                description
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              bottomText
              bottomTitle
            }
          }
        }
      }
    }
    allContentfulRegion(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
